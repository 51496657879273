import { motion } from 'framer-motion';

export type PageLoaderProps = {
  key?: string;
  onAnimationComplete?: () => void;
  repeat?: number;
  animationDuration?: number;
};

export const PageLoader: React.FC<PageLoaderProps> = ({
  key,
  onAnimationComplete,
  repeat = Infinity,
  animationDuration = 1.75,
}) => {
  return (
    <div key={key} style={{ position: 'absolute', width: 350, height: 350 }}>
      <motion.div
        onAnimationComplete={onAnimationComplete}
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1, 1, 0] }}
        transition={{
          times: [0, 0.3, 0.85, 1],
          duration: animationDuration,
          repeat: repeat,
          repeatType: 'loop',
        }}
        style={{
          width: 350,
          height: 350,
          borderRadius: '50%',
          backgroundColor: '#185DF1',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: [0, 0, 1, 1, 0] }}
        transition={{
          duration: animationDuration,
          times: [0, 0.2, 0.3, 0.85, 1],
          repeat: repeat,
          repeatType: 'loop',
        }}
        style={{
          width: 135,
          height: 135,
          position: 'absolute',
          top: '50%',
          left: '50%',
          translateX: '-50%',
          translateY: '-50%',
        }}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 23 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3744_7139)">
            <g clipPath="url(#clip1_3744_7139)">
              <path
                d="M22.8367 7.24905L16.288 0.70041C15.8402 0.252607 15.2317 0 14.5963 0H7.71089C7.54631 0 7.41235 0.133958 7.41235 0.298535V3.7891C7.41235 3.95368 7.54631 4.08764 7.71089 4.08764H15.2891C15.4537 4.08764 15.5876 4.22159 15.5876 4.38617V7.87674C15.5876 8.04132 15.7216 8.17527 15.8862 8.17527H22.4578C22.94 8.17527 23.1811 7.59351 22.8405 7.25288L22.8367 7.24905Z"
                fill="white"
              />
              <path
                d="M15.2853 10.8008H7.70706C7.54248 10.8008 7.40852 10.6669 7.40852 10.5023V7.01173C7.40852 6.84715 7.27456 6.7132 7.10999 6.7132H0.54221C0.0599602 6.7132 -0.181164 7.29496 0.159472 7.63559L6.71194 14.1881C7.15974 14.6359 7.76829 14.8885 8.40364 14.8885H15.2891C15.4537 14.8885 15.5876 14.7545 15.5876 14.5899V11.0994C15.5876 10.9348 15.4537 10.8008 15.2891 10.8008H15.2853Z"
                fill="white"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_3744_7139">
              <rect width="100%" height="100%" fill="white" />
            </clipPath>
            <clipPath id="clip1_3744_7139">
              <rect width="100%" height="100%" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </motion.div>
    </div>
  );
};
