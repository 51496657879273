'use client';

import { PageLoader } from '@salesport/ui-components';

export default function Loading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <PageLoader />
    </div>
  );
}
